import { BsInstagram } from 'react-icons/bs';
import { MdEmail, MdPhone } from 'react-icons/md';
import { Flex, IconButton } from '@chakra-ui/react';
import { INSTAGRAM_URL } from '../constants';

export const EmailButton = ({ mainColor, hoverColor, iconColor, iconSize }) => {
  return (
    <IconButton
      rounded={'full'}
      aria-label="Email"
      onClick={e => {
        window.location.href = `mailto:hello@velvetmooncoffee.com`;
        e.preventDefault();
      }}
      _hover={{
        bg: hoverColor,
      }}
      icon={<MdEmail color={iconColor} size={iconSize} />}
      backgroundColor={mainColor}
    />
  );
};

export const InstagramButton = ({
  mainColor,
  hoverColor,
  iconColor,
  iconSize,
}) => {
  return (
    <IconButton
      rounded={'full'}
      aria-label="Instagram"
      onClick={() => {
        window.open(INSTAGRAM_URL, '_blank');
      }}
      _hover={{
        bg: hoverColor,
      }}
      icon={<BsInstagram color={iconColor} size={iconSize} />}
      backgroundColor={mainColor}
    />
  );
};

export const PhoneButton = ({ mainColor, hoverColor, iconColor, iconSize }) => {
  return (
    <IconButton
      rounded={'full'}
      aria-label="Phone Number"
      _hover={{
        bg: hoverColor,
      }}
      onClick={() => {
        window.location.href = `tel:+18458530388`;
      }}
      icon={<MdPhone color={iconColor} size={iconSize} />}
      backgroundColor={mainColor}
    />
  );
};

import { Container } from '@chakra-ui/react';
import { ContactSection } from './ContactSection';
import { HeroUnit } from './HeroUnit';
import { EspressoBarSection } from './EspressoBarSection';
import { AboutUsSection } from './AboutUsSection';
import { Navbar } from './nav/Navbar';
import { EventTypeCardsSection } from './EventTypeCardsSection';
import { PackageOptionsStack } from './PackageOptionsStack';
import { PhotoCarouselSection } from './PhotoCarouselSection';

export const AppContent = () => {
  return (
    <Container maxW="full" paddingX={0}>
      <HeroUnit />
      {/* <Navbar /> */}
      <AboutUsSection />
      <EspressoBarSection />

      <ContactSection />
    </Container>
  );
};

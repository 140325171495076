import {
  Heading,
  Stack,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
  Image,
  IconButton,
  Box,
} from '@chakra-ui/react';
import '@fontsource/montserrat';
import '@fontsource/bowlby-one';
import { BsInstagram } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { BsChevronCompactDown } from 'react-icons/bs';
import {
  BRAND_NEUTRAL_COLOR,
  INSTAGRAM_URL,
  BRAND_PRIMARY_COLOR,
} from '../constants';
import {
  EmailButton,
  InstagramButton,
  PhoneButton,
} from '../util/SocialButtons';

export const HeroUnit = () => {
  return (
    <Flex
      w={'full'}
      h={'100vh'}
      backgroundImage={require('../images/HeroBackground.jpg')}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        // bgGradient={'linear(to-r, blackAlpha.900,#39315dde)'}
        backgroundColor={'whiteAlpha.700'}
      >
        <Stack maxW={'9xl'} align={'flex-start'} spacing={6} textAlign="center">
          <Image
            alignSelf="center"
            src={require('../images/wordmark/VMC_Wordmark_Black.png')}
            maxH={220}
          />
          <Heading
            color={'black'}
            fontWeight={'bold'}
            textAlign={'center'}
            alignSelf={'center'}
            textTransform={'uppercase'}
          >
            Mobile Espresso Bar & Coffee Catering Service
          </Heading>

          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}
            gap={8}
          >
            <Stack direction={'row'}>
              <InstagramButton
                iconColor={BRAND_NEUTRAL_COLOR}
                hoverColor={BRAND_PRIMARY_COLOR}
                mainColor={'black'}
                iconSize={'20px'}
              />
              <EmailButton
                mainColor={'black'}
                hoverColor={BRAND_PRIMARY_COLOR}
                iconColor={BRAND_NEUTRAL_COLOR}
                iconSize={'20px'}
              />
              <PhoneButton
                mainColor={'black'}
                hoverColor={BRAND_PRIMARY_COLOR}
                iconColor={BRAND_NEUTRAL_COLOR}
                iconSize={'20px'}
              />
            </Stack>
            {/* TODO: add hash scroll setup, then make the hero unit 100 again
            <IconButton
              colorScheme={'#a35428'}
              bg={'#d49c7f'}
              rounded={'full'}
              px={6}
              _hover={{
                bg: '#8c3839',
              }}
              icon={<BsChevronCompactDown />}
            /> */}
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
};

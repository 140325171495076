import React from 'react';
import {
  ChakraProvider,
  extendTheme
} from '@chakra-ui/react';
import { AppContent } from './components/AppContent';
import '@fontsource/montserrat';
import '@fontsource/bowlby-one';
import '@fontsource/dm-serif-text';
import '@fontsource/oswald'
import '@fontsource/quattrocento'
import { BsFolder } from 'react-icons/bs';


const theme = extendTheme({
  fonts: {
    heading: `'Oswald', sans-serif`,
    body: `'Quattrocento', serif`,
  },
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppContent />
    </ChakraProvider>
  );
}

export default App;

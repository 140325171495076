import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { BRAND_NEUTRAL_COLOR, BRAND_PRIMARY_COLOR } from '../constants';

const Card = ({ heading, description, image }) => {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      // borderColor={'#d49c7f'}
      // borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      //p={8}
      backgroundColor={BRAND_NEUTRAL_COLOR}
    >
      <Stack align={'start'} spacing={2}>
        <Image
          width={'100%'}
          maxWidth={'max-content'}
          minHeight={'180px'}
          background={'cover'}
          src={image}
          alt="Mobile Espresso Bar"
          maxH={'max-content'}
        />
        <Box mt={2} gap={6} p={8}>
          <Heading
            size="md"
            color={BRAND_PRIMARY_COLOR}
            textTransform={'uppercase'}
          >
            {heading}
          </Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export const EspressoBarSection = () => {
  return (
    <Box p={4} marginY={'24px'}>
      <Stack spacing={4} as={Container} maxW={'5xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          ESPRESSO BAR PACKAGE
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'md' }}>
          Elevate your next event and treat your guests to an open espresso bar!
          Our espresso bar catering package brings the full coffee shop
          experience to you! Here is an overview of what is included in our base
          espresso catering package.
        </Text>
      </Stack>

      <Container maxW={'8xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            image={require('../images/package-cards/ColtOnBar.jpg')}
            heading={'Barista Service'}
            description="Our base package includes up to 3 hours of barista 
            service. Our mobile bar is staffed with two experienced baristas. 
            Our baristas have the experience and training to give you a quality 
            pour every time. "
          />
          <Card
            image={require('../images/package-cards/HorizontalCartPic.jpg')}
            heading={'Mobile Espresso Bar'}
            description="Our flexible setup is designed to fit both 
            aesthetically and functionally into any event. 
            We can setup inside or outside. All we need is access 
            to an outlet! Full setup, styling and breakdown of our 
            mobile espresso bar is included in all espresso packages. "
          />
          <Card
            image={require('../images/package-cards/EspressoPour.jpg')}
            heading={'Full Cafe Menu'}
            description="All of our 
            espresso packages include unlimited access to a full 
            cafe menu of espresso drinks. Latte, cappucino, cortado, 
            hot or cold. We're prepared to make your favorite coffee order, or two.  "
          />

          <Card
            image={require('../images/package-cards/IcedCoffee.jpg')}
            heading={'House Made Syrups'}
            description="Your choice of two house made syrups. 
            We have a curated list of recipes to choose from. 
            We add to this list frequently with new syrups for every season. 
            But we would also love to work with you to create a custom syrup! 
            Tell us a few of your favorite flavors and we can work 
            with you to find your new favorite syrup flavor!"
          />

          <Card
            image={require('../images/package-cards/Cups.jpg')}
            heading={'All the Essentials'}
            description="Cups, lids, napkins, sugars, all of 
              the coffee shop essentials. Our regular milk options 
              are Oat and Whole, but we are happy to bring an 
              additional option upon request."
          />
        </Flex>
      </Container>
    </Box>
  );
};

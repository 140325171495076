import {
  Flex,
  Image,
  Box,
  useColorModeValue,
  Heading,
  Text,
  Stack,
} from '@chakra-ui/react';
import { BRAND_NEUTRAL_COLOR, BRAND_PRIMARY_COLOR } from '../constants';
import useCheckIsMobile from '../util/CheckIsMobile';

export const AboutUsSection = () => {
  const isMobile = useCheckIsMobile();

  return (
    <Flex
      justify="center"
      //paddingTop={16}
      //paddingBottom={16}
      flexDirection={'row'}
      margin={16}
    >
      <Flex
        flexWrap={{
          base: 'wrap',
          sm: 'wrap',
          md: 'nowrap',
          lg: 'nowrap',
          xl: 'nowrap',
          '2xl': 'nowrap',
        }}
        maxW={'6xl'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        // boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        display={'flex'}
        backgroundColor={BRAND_NEUTRAL_COLOR}
        flexDirection={'row'}
        gap={{
          base: 8,
          sm: 8,
          md: 16,
          lg: 16,
          xl: 16,
        }}
      >
        <Image
          borderRadius={isMobile ? 'full' : 'md'}
          boxSize={isMobile ? '280px' : 'md'}
          maxWidth="max-content"
          src={require('../images/AboutUs.jpg')}
          alt="About Us Photo"
        />
        <Stack direction="column">
          <Box>
            <Heading color={'#8C3839'} transform={'uppercase'}>
              ABOUT US
            </Heading>
          </Box>
          <Box>
            <Text>
              We are Courtney and Colt of Velvet Moon Coffee. We are a specialty
              coffee catering service based in the Catskills / Hudson Valley
              region of New York State. <br />
              <br />
              We are two coffee enthusiasts who believe both in the craft of
              great coffee, and in the power of coffee as a community catalyst.
              We are proud to offer a service that can bring the comfort of a
              local coffee shop right to your gathering.
              <br />
              <br />
              We have a full mobile espresso bar that can accomodate any event.
              We are here to caffienate your wedding guests and get them back on
              the dance floor. Keep the juices flowing during an upstate
              corporate retreat. Really anywhere you have a need for craft
              coffee, we would love to be there for you!
            </Text>
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
};
